<template>
    <div class="list-pagination">
        <div
            v-if="labelOpen"
            class="sub-block"
        >
            <div class="sub-title">
                {{ $t('ListPagination.title') }}
            </div>
            <div
                class="label-group"
            >
                <button
                    v-for="item in label"
                    :key="item"
                    class="label-item"
                    :class="{labelActive:isActive(item),labelUnactive:!isActive(item)}"
                    @click="transferLabel(item)"
                >
                    {{ item }}
                </button>
            </div>
        </div>
        <div class="list-group">
            <v-progress-circular
                v-if="!showData"
                indeterminate
                color="orange"
            />
            <div
                v-if="showData"
                style="width: 100%;"
            >
                <div
                    v-for="(item, index) in listData"
                    :key="index"
                    class="list-item"
                    @click="goToLink(item)"
                >
                    <img
                        class="list-img"
                        width="280px"
                        height="210px"
                        :src="image(item.image)"
                        alt=""
                    >
                    <div class="list-content">
                        <div class="list-title">
                            {{ item.title }}
                        </div>
                        <div class="list-caption">
                            {{ item.description }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination">
            <v-pagination
                v-model="page"
                :length="MaxPage"
                :total-visible="6"
                color="orange"
            />
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    components: {},
    props: {
        showData: {
            type: Boolean,
            default: false,
        },
        blockData: {
            type: Object,
            default: () => ({}),
        },
        routerName: {
            type: String,
            default: () => '',
        },
        labelOpen: {
            type: Boolean,
            default: false,
        },
        label: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            page: this.ToPage(),
            MaxList: 6,
        };
    },
    computed: {
        MaxPage() {
            return Math.ceil(this.filterLabel().length / this.MaxList);
        },
        listData() {
            // eslint-disable-next-line max-len
            return _.slice(this.filterLabel(), (this.page - 1) * this.MaxList, this.page * this.MaxList);
        },
    },
    watch: {
        page() {
            // eslint-disable-next-line max-len
            this.$router.push({ name: this.$route.name, query: { page: this.page, label: this.$route.query.label } });
        },
    },
    created() {
        this.filterLabel();
    },
    mounted() {
    },
    methods: {
        isActive(item) {
            return item === this.$route.query.label;
        },
        transferLabel(item) {
            // eslint-disable-next-line max-len
            this.$router.push({ name: this.$route.name, query: { page: 1, label: item } });
            this.page = 1;
            this.filterLabel();
        },
        filterLabel() {
            if (this.$route.query.label) {
                // eslint-disable-next-line max-len
                return _.filter(this.blockData.items, _.matches({ tags: [this.$route.query.label.replace(/%20/g, ' ')] }));
            }
            return this.blockData.items;
        },
        goToLink(item) {
            this.$router.push({ name: this.routerName, params: { id: item.id } });
        },
        ToPage() {
            if (this.$route.query.page) {
                return Number(this.$route.query.page);
            }
            return 1;
        },
        image(item) {
            const file = `https://${process.env.VUE_APP_BASE_URL + item}`;
            return file;
        },
    },
};
</script>

<style lang="scss">
.labelActive{
  background-color:red
}
.labelUnactive{
  background-color: $color-orange;
}
.list-pagination{
  .sub-block{
    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
    .sub-title{
      min-width: 110px;
    }
    .label-group{
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
      .label-item{
        color:white;
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: 0.1em;
        border-radius: 2px;
        padding: 5px;
        margin-right:10px;
        margin-bottom: 10px;
      }
    }
  }
  .list-group{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .list-item{
      display: flex;
      padding: 25px 0;
      border-bottom: 1px solid $color-orange;
      cursor: pointer;
      //圖片暫時顏色
      .list-img{
        background-color: #d9d9d9;
      }
      .list-content{
        line-height: 2;
        letter-spacing: 0.1em;
        margin-left: 25px;
        margin-top: -10px;
        .list-title{
          font-size: 21px;
          font-weight: 700;
          text-align: justify;
          color: $color-blue;
          overflow:hidden;
          display:-webkit-box;
          -webkit-box-orient:vertical;
          -webkit-line-clamp:2;
        }
        .list-caption{
          font-size: 16px;
          font-weight: 700;
          text-align: justify;
          overflow:hidden;
          display:-webkit-box;
          -webkit-box-orient:vertical;
          -webkit-line-clamp:2;
        }
      }
    }
  }
  .pagination{
    margin: 25px;
    .v-pagination {
      justify-content: flex-start;
    }
  }
}

@media screen and (max-width: 960px) {
  .list-pagination{
    .sub-block{
      flex-direction: column;
      .label-group{
        margin-left: 0;
        .label-item{
          font-size: 14px;
          margin-right:5px;
          margin-bottom:5px;
        }
      }
    }
    .list-group{
      align-items: center;
      .list-item{
        max-width: 330px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .list-content{
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
    .pagination{
      margin: 25px 0;
      .v-pagination {
        justify-content: center;
      }
    }
  }
}

</style>
